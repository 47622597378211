import React from 'react'

import { Autocomplete } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { Gender } from '@azos/core'
import { ChatAvatarCard } from '@azos/shared'
import { Input, RadioButton } from '@azos/shared/src/components/v2'
import { Button } from '@azos/shared/src/components/v2/Button'
import { Profession } from '@domain/models'
import { makeCreateQuotationService } from '@main/factories/services'
import { BackButton } from '@presentation/components/molecules/Upsell'
import { useSimulation } from '@presentation/hooks/useSimulation.hooks'
import { useRouter } from '@presentation/providers/RouterProvider'
import { useUpsell } from '@presentation/providers/UpsellProvider'
import dayjs from 'dayjs'
import { Formik, Form } from 'formik'

import { salaries } from './mocks'
import {
  RadioButtonOptions,
  SalaryProfessionData,
  SalaryProfessionProps,
} from './SalaryProfession.props'
import Root from './SalaryProfession.styles'
import { validationSchema } from './SalaryProfession.validation'

const createQuotationService = makeCreateQuotationService()

const formatGender = (label: string) => {
  const genderCases = {
    Masculino: Gender.MALE,
    Feminino: Gender.FEMALE,
  }

  return genderCases[label] ?? ''
}

const SalaryProfession = ({ professions, onGoBack }: SalaryProfessionProps) => {
  const { useStep, formData, setFormData } = useSimulation()
  const { specialistUpsell } = useUpsell()
  const router = useRouter()
  const { user } = useAuth()

  const [step] = useStep

  if (step !== 2) {
    return null
  }

  React.useEffect(() => {
    analytics.dashboard.upsell.simulation.viewSimulationPageProfessionSalary.execute()
  }, [])

  const [profession, setProfession] = React.useState<
    Partial<{
      id: string
      description: string
      requiresDescription: boolean
      name: string
    }>
  >(formData.profession ?? {})

  const professionsOptions = React.useMemo<string[]>(
    () =>
      professions
        ?.filter(item => !item.requiresDescription)
        .map(item => item.name) || [],
    [professions],
  )

  const noOptionText = React.useMemo<string>(
    () =>
      professions?.find(item => item.requiresDescription)?.name ||
      'Não encontrado',
    [professions],
  )

  const requiredDescription = React.useMemo<boolean>(
    () =>
      professions?.find(item => item.professionId === profession?.id)
        ?.requiresDescription || false,
    [professions, profession.id],
  )

  const selectProfession = React.useCallback(
    (
      profession: Profession,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => void,
      description = '',
    ) => {
      const { professionId, name, requiresDescription } = profession

      setProfession({
        id: professionId,
        description,
        requiresDescription,
        name,
      })
      setFieldValue(
        'profession',
        { id: professionId, description, requiresDescription, name },
        !requiresDescription,
      )
    },
    [],
  )

  const handleProfessionChange = React.useCallback(
    (
      value: string,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => void,
    ) => {
      const profession = professions?.find(item => item.name === value)

      if (profession) {
        selectProfession(profession, setFieldValue)
      }
    },
    [professions, selectProfession],
  )

  const initialValues: SalaryProfessionData = {
    profession: formData.profession ?? {},
    salary: formData.salary ? Number(formData.salary) : null,
  }

  const handleSubmit = async values => {
    const data = {
      ...formData,
      ...values,
    }

    setFormData(data)

    analytics.dashboard.upsell.simulation.continueSimulationPageProfessionSalary.execute()

    await createQuotationService.execute({
      birthDate: dayjs(
        user?.insuredUser?.birthDate as string,
        'DD/MM/YYYY',
      ).toDate(),
      gender: formatGender(user?.insuredUser?.gender ?? ''),
      height: Number(data.height),
      weight: Number(data.weight),
      isSmoker: Boolean(data.is_smoker),
      professionId: data.profession?.id as string,
      professionDescription: data.profession?.description,
      salary: data.salary as number,
      cpf: user?.insuredUser?.cpf as string,
    })

    router.push('/simular-novo-seguro/calculadora')
  }

  const options = React.useMemo<RadioButtonOptions[]>(
    () =>
      salaries
        .sort((a, b) => (b.amount <= a.amount ? 1 : -1))
        .map(item => ({
          value: item.amount,
          label: item.label,
        })),
    [],
  )

  const handleGoBack = () => {
    analytics.dashboard.upsell.simulation.goBackSimulationPageProfessionSalary.execute()

    onGoBack()
  }

  return (
    <div>
      <BackButton onClick={handleGoBack} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          setFieldValue,
          errors,
          isValid,
          values,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
        }) => {
          const hasDefaultValues =
            !!initialValues?.profession?.name || !!initialValues?.salary
          const isDirty = hasDefaultValues || dirty
          return (
            <Form>
              <Root>
                <ChatAvatarCard
                  title="Muito bom!"
                  subtitle="Vamos precisar atualizar algumas informações."
                  description="Preencha as informações iniciais a seguir."
                  specialist={specialistUpsell}
                />
                <div className="salary-profession__card">
                  <p> Conta pra gente: qual é a sua profissão atual? </p>
                  <div className="salary-profession__card--autocomplete">
                    {professions && (
                      <Autocomplete
                        data-testid="upsell-profession"
                        name="profession"
                        value={values.profession}
                        inputValue={values.profession?.name}
                        onChange={vl => {
                          handleProfessionChange(vl, setFieldValue)
                        }}
                        fullWidth
                        placeholder="Selecione uma ocupação..."
                        options={professionsOptions}
                        disabled={isSubmitting}
                        noOptionsText={noOptionText}
                        error={!!errors.profession}
                        helperText={
                          errors
                            ? (errors as SalaryProfessionData).profession?.id
                            : undefined
                        }
                      />
                    )}

                    {requiredDescription && (
                      <Input
                        className="salary-profession__card--description"
                        autoFocus
                        name="profession.description"
                        label="Especifique"
                        value={values.profession?.description || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        error={
                          !!(errors as SalaryProfessionData).profession
                            ?.description
                        }
                        helperText={
                          errors
                            ? (errors as SalaryProfessionData).profession
                                ?.description
                            : undefined
                        }
                        fullWidth
                      />
                    )}
                  </div>
                  <div className="salary-profession__card--salary">
                    <p> E qual é a sua renda mensal? </p>
                    <RadioButton
                      name="salary"
                      options={options}
                      value={values.salary}
                      onChange={vl => {
                        setFieldValue('salary', vl)
                      }}
                    />
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    fullWidth
                    className="salary-profession__card--button-continue"
                    disabled={!isValid || !isDirty}
                  >
                    Continuar
                  </Button>
                </div>
              </Root>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default SalaryProfession
