import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    .imc-smoker__card {
      max-width: 440px;
      width: 100%;
      background-color: ${theme.palette.neutral[50]};
      margin-top: 24px;
      padding: 24px;

      p {
        margin: 24px 0 8px 0;
        font-size: 14px;
        font-weight: 600;
      }

      &--radio-button {
        width: 100%;
      }

      &--input {
        display: flex;

        & > * + * {
          margin-left: 12px;
        }
      }

      &--button {
        display: flex;

        &-continue {
          margin-top: ${theme.spacing[4]}px;
          margin-bottom: ${theme.spacing[2]}px;
        }
      }
    }
  `,
)

export default Root
