import { PaymentMethod } from '@azos/core'
import { Utils } from '@azos/shared'
import * as Yup from 'yup'

import { ICreditCardValidator } from './components'

export const validationSchema = (validator?: ICreditCardValidator) =>
  Yup.object().shape({
    paymentMethod: Yup.string().required('Você deve escolher uma opção'),
    ccnumber: Yup.string().when('paymentMethod', {
      is: (field: PaymentMethod) => field === PaymentMethod.CREDIT_CARD,
      then: Yup.string()
        .test({
          name: 'Credit card',
          message: 'O número do cartão é inválido',
          test: value => {
            if (value && validator) {
              const sanitizedValue = Utils.sanitizer.onlyNumber(value)
              return validator.validateCreditCardNumber(sanitizedValue)
            }
            return true
          },
        })
        .min(15, 'O número do cartão é inválido')
        .required('O número do cartão é obrigatório'),
    }),
    ccname: Yup.string().when('paymentMethod', {
      is: (field: PaymentMethod) => field === PaymentMethod.CREDIT_CARD,
      then: Yup.string()
        .test({
          name: 'full_name',
          test: value => {
            const splitValue = value?.trim().split(' ')
            return (splitValue && splitValue?.length >= 2) || false
          },
          message: 'O nome é obrigatório',
        })
        .required('O nome é obrigatório'),
    }),
    exp_date: Yup.string().when('paymentMethod', {
      is: (field: PaymentMethod) => field === PaymentMethod.CREDIT_CARD,
      then: Yup.string()
        .length(5, 'A data de vencimento é inválida')
        .test({
          name: 'Expiry',
          message: 'A data de vencimento é inválida',
          test: value => {
            if (value && validator) {
              return validator.validateExpirationString(value)
            }
            return true
          },
        })
        .required('A data de vencimento é obrigatória'),
    }),
    cvv: Yup.string().when('paymentMethod', {
      is: (field: PaymentMethod) => field === PaymentMethod.CREDIT_CARD,
      then: Yup.string()
        .min(3, 'O código de segurança é inválido')
        .max(4, 'O código de segurança é inválido')
        .test({
          name: 'cvv',
          message: 'O número de segurança é inválido',
          test(value) {
            if (value && validator) {
              const sanitizedValue = Utils.sanitizer.onlyNumber(
                this.parent.ccnumber,
              )
              const brand = validator.getBrandByCreditCardNumber(sanitizedValue)

              return validator.validateCVV(value, brand)
            }
            return true
          },
        })
        .required('O número de segurança é obrigatório'),
    }),
  })
