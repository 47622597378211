import React, { useState } from 'react'

import analytics from '@azos/analytics'
import { useMediaQuery, Utils } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'

import { CardBenefitsProps } from './CardBenefits.props'
import { BadgeContent, Root } from './CardBenefits.styles'
import { BenefitDetailsCard } from './components'

const CardBenefits: React.VFC<CardBenefitsProps> = props => {
  const {
    cardImage,
    image,
    description,
    subtitle,
    title,
    badge,
    backgroundColor,
    slug,
  } = props
  const [benefitDetails, setBenefitDetails] = useState(false)

  const { isMobile } = useMediaQuery()

  const imageContent = React.useMemo(() => {
    if (isMobile) {
      return cardImage
    }

    return image
  }, [cardImage, image, isMobile])

  const benefitsAnalytics = () => {
    if (slug) {
      analytics.dashboard.benefits.view.benefitsPageDetails.execute({
        partner: slug,
      })
    }
  }

  return (
    <Root
      $backgroundColor={backgroundColor}
      onClick={() => {
        setBenefitDetails(true)
        benefitsAnalytics()
      }}
    >
      <div className="card-content">
        <div className="card-content__img">
          <img src={imageContent} alt={title} />
        </div>
        <div className="card-content__text">
          <div className="card-content__text--title">
            <Text variant="h5">{title}</Text>
            <i className="icon-chevron-right" />
          </div>
          {!Utils.object.isEmpty(badge) && (
            <BadgeContent
              color={badge?.backgroundColor}
              textColor={badge?.textColor}
            >
              {badge?.icon && <i className={badge.icon} />}
              {badge?.label}
            </BadgeContent>
          )}
          <Text>{subtitle}</Text>
          <Text variant="body2">{description}</Text>
        </div>
      </div>
      <BenefitDetailsCard
        open={benefitDetails}
        onClose={() => setBenefitDetails(false)}
        benefit={props}
      />
    </Root>
  )
}

export default CardBenefits
