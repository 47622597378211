const isServer = typeof window === 'undefined'

export type ICreditCardValidator = {
  validateCreditCardNumber(number: string): boolean
  validateExpirationString(expDate: string): boolean
  validateCVV(value: string, brand: string): boolean
  getBrandByCreditCardNumber(number: string): string
}

export const makeCreditCardValidator = (): ICreditCardValidator | undefined =>
  isServer ? undefined : (window?.Iugu?.utils as ICreditCardValidator)

export const makeFakeCreditCardValidator = (): ICreditCardValidator =>
  new FakeCreditCardValidator()

class FakeCreditCardValidator implements ICreditCardValidator {
  validateCreditCardNumber(number: string): boolean {
    return true
  }
  validateExpirationString(expDate: string): boolean {
    return true
  }
  validateCVV(value: string, brand: string): boolean {
    return true
  }
  getBrandByCreditCardNumber(number: string): string {
    return 'mastercard'
  }
}
