import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .title,
    .description {
      text-align: center;
    }

    .title {
      margin-top: ${theme.spacing[8]}px;
    }

    .description {
      margin: ${theme.spacing[3]}px 0 ${theme.spacing[6]}px;
      color: #4e4b66;
    }

    .button:first-of-type {
      margin-bottom: ${theme.spacing[2]}px;
    }
  `,
)

export default Root
