import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing[4]}px ${theme.spacing[7]}px;

    @media (min-width: ${theme.breakpoints.sm}px) {
      margin: ${theme.spacing[4]}px ${theme.spacing[7]}px;
    }

    .benefits {
      &__title {
        margin-bottom: ${theme.spacing[3]}px;
      }

      &__description {
        display: inline-block;
        margin-bottom: ${theme.spacing[7]}px;
      }
    }
  `,
)

export const BenefitsDivider = styled('hr')(
  ({ theme }) => css`
    width: calc(100% + 40px);
    //border: 0.5px solid ${theme.palette.neutral[200]};
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: ${theme.palette.neutral[200]};
    margin: 24px 0px 24px -20px;
  `,
)

export default Root
