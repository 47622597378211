const salaries = [
  {
    amount: 1500,
    value: 0,
    label: 'Até R$ 1.500,00',
  },
  {
    amount: 3000,
    value: 3000,
    label: 'R$ 1.500 a R$ 3 mil',
  },
  {
    amount: 5000,
    value: 6000,
    label: 'R$ 3 a R$ 5 mil',
  },
  {
    amount: 7000,
    value: 9000,
    label: 'R$ 5 a R$ 7 mil',
  },
  {
    amount: 10000,
    value: 12000,
    label: 'R$ 7 a R$ 10 mil',
  },
  {
    amount: 15000,
    value: 15000,
    label: 'R$ 10 a R$ 15 mil',
  },
  {
    amount: 20000,
    value: 18000,
    label: '+R$ 15 mil',
  },
]

export { salaries }
