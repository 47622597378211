import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  PaymentChannel,
  PaymentFrequencyEventType,
} from '@azos/analytics/src/domain/models'
import { CheckoutPaymentFrequency } from '@azos/core'
import {
  RadioButton,
  RadioButtonData,
  RadioButtonOptions,
} from '@azos/shared/src/components/v2'

import { PaymentPeriodProps } from './PaymentPeriod.props'
import { Root } from './PaymentPeriod.styles'

const VALUES: RadioButtonOptions[] = [
  { label: 'Mensal', value: CheckoutPaymentFrequency.MONTHLY },
  { label: 'Anual (à vista)', value: CheckoutPaymentFrequency.ANNUAL },
]

const PaymentPeriod: React.FC<PaymentPeriodProps> = ({
  value = CheckoutPaymentFrequency.MONTHLY,
  onChange,
}) => {
  const [radioValue, setRadioValue] = React.useState(value)

  const customCheckbox = (value: CheckoutPaymentFrequency) => {
    const paymentFrequencyCases: {
      [key in CheckoutPaymentFrequency]: PaymentFrequencyEventType
    } = {
      [CheckoutPaymentFrequency.MONTHLY]: PaymentFrequencyEventType.MONTHLY,
      [CheckoutPaymentFrequency.ANNUAL]: PaymentFrequencyEventType.ANNUAL,
    }

    if (paymentFrequencyCases[value]) {
      analytics.checkout.selectPaymentFrequency.execute({
        paymentFrequency: paymentFrequencyCases[value],
        paymentChannel: PaymentChannel.UPSELL,
      })
    }

    setRadioValue(value)
    onChange(value)
  }

  return (
    <Root>
      <div className="payment-period">
        <Typography className="payment-period__subtitle">
          Tenha a liberdade de escolher o que é mais prático para você e pague
          por ano ou mês.
        </Typography>
      </div>
      <RadioButton
        name="payment_frequency"
        options={VALUES}
        value={radioValue}
        onChange={(value: RadioButtonData) => {
          customCheckbox(value as CheckoutPaymentFrequency)
        }}
      />
    </Root>
  )
}

export default PaymentPeriod
