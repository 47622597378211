import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .pix {
      &__submit-button {
        margin-top: ${theme.spacing[6]}px;
      }

      &__card-qr-code {
        margin: ${theme.spacing[2]}px 0 ${theme.spacing[3]}px;
      }

      &__copy-card-content {
        margin: 12px 0 ${theme.spacing[5]}px;
        overflow: hidden;

        &--copy-button {
          margin-top: ${theme.spacing[2]}px;
        }

        &--text-content {
          max-width: 650px;

          .card_copy_text__value {
            max-width: 50vw;
          }
        }
      }

      &__description {
        display: block;
        color: ${theme.palette.neutral[400]};
      }

      &__title {
        color: ${theme.palette.neutral[400]};
      }

      &__timeout {
        &-content {
          display: flex;
          align-items: flex-start;
          background: ${theme.palette.neutral[100]};
          margin-top: ${theme.spacing[3]}px;
          padding: 12px;

          i {
            font-size: 1.25rem;
            color: ${theme.palette.neutral[300]};
            margin-right: ${theme.spacing[3]}px;
          }

          &--text {
            display: block;

            &:not(:first-of-type) {
              margin-top: 8px;
            }
          }
        }
      }
    }
  `,
)
