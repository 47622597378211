import React from 'react'

import { SimulationContext } from '@presentation/providers/SimulationProvider/SimulationProvider'

const useSimulation = () => {
  const context = React.useContext(SimulationContext)

  if (!context) {
    throw new Error('useSimulation must be used within a SimulationProvider')
  }

  return context
}

export { useSimulation }
