import { css, styled } from '@azos/shared'

type RootProps = {
  $hasExpiresAt: boolean
}

export const Root = styled('div')<RootProps>(
  ({ $hasExpiresAt, theme }) => css`
    margin-top: 54px;

    i {
      font-size: 24px;
    }

    .duedate-card {
      &__title {
        margin: 12px 0;
        display: flex;

        i {
          margin-left: 12px;
        }
      }

      &__textarea {
        background-color: ${theme.palette.neutral[50]};
      }

      &__textField {
        cursor: pointer;
        padding: 18px 16px;
        background: ${theme.palette.neutral[50]};
        width: 100%;
        display: flex;
        justify-content: space-between;

        ${!$hasExpiresAt
          ? css`
              &-text {
                color: ${theme.palette.neutral[300]};
              }
            `
          : ''}

        i {
          color: ${$hasExpiresAt ? theme.palette.primary.main : '#090A0B'};
        }
      }
    }
  `,
)
