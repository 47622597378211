import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    .refused-coverages {
      width: 100%;
      margin-top: 24px;

      &__aside {
        @media (min-width: 600px) {
          max-width: 295px;
          width: 100%;
          height: auto;
          padding: 18px;
          position: inherit;
          background-color: #ffffff;
          margin-top: ${theme.spacing[6]}px;
        }

        position: fixed;
        bottom: 80px;
        width: 100vw;
        left: 0;
        margin: 0;
        z-index: 1199;

        &--icon {
          display: flex;
          align-items: center;
          margin-bottom: 36px;

          span {
            font-weight: ${theme.typography.fontWeightBold};
          }

          i {
            font-size: 24px;
            color: #a3aab9;
            margin-right: 12px;
          }
        }

        &--info {
          display: flex;
          align-items: center;

          p {
            color: #a3aab9;
            font-size: 14px;
          }

          strong {
            padding: 0 10px;
          }
        }

        button {
          @media (min-width: ${theme.breakpoints.md}px) {
            margin-top: 32px;
          }
          padding: 12px;
          margin: 12px 0 0 0;
        }
      }

      &-content {
        width: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme.spacing[3]}px;

        &__subtitle {
          margin-top: 8px;
          display: block;
        }
      }
    }

    .empty-state__coverages {
      font-size: 16px;
    }

    .empty-state__aside--mobile {
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }

      button {
        padding: 12px;
        margin: 32px 0 0 0;
      }

      &__icon {
        display: flex;
        align-items: center;
        margin-top: 16px;

        i {
          font-size: 24px;
          color: #a3aab9;
          margin-right: 12px;
        }

        span {
          font-weight: 500;
          font-size: 14px;
          color: #505969;
        }
      }
    }
  `,
)

export default Root
