import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .my-policies {
      &__header {
        padding: ${theme.spacing[6]}px 0 0;
        background: ${theme.palette.neutral[400]};

        @media (min-width: ${theme.breakpoints.sm}px) {
          padding: ${theme.spacing[6]}px 0 ${theme.spacing[2]}px;
          margin-bottom: 0;
        }

        i {
          color: ${theme.palette.neutral[400]};
          background: ${theme.palette.neutral[300]};
          padding: ${theme.spacing[2]}px;
          border-radius: 50%;
          font-size: 1.5rem;
        }

        &--title {
          display: block;
          margin-top: ${theme.spacing[2]}px;
          color: ${theme.palette.neutral[50]};
        }

        &--description {
          display: block;
          margin-top: ${theme.spacing[1]}px;
          color: ${theme.palette.neutral[50]};
        }
      }

      &__mobile-list-group {
        margin: ${theme.spacing[4]}px ${theme.spacing[3]}px;
      }

      &__list-group {
        padding: ${theme.spacing[4]}px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;

        @media (min-width: ${theme.breakpoints.sm}px) {
          padding: ${theme.spacing[3]}px ${theme.spacing[4]}px
            ${theme.spacing[5]}px;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .alert {
      margin: -32px -18px 48px;

      @media (min-width: 1024px) {
        margin: 0 0 48px;
      }

      .alert-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
          max-width: 400px;
        }

        button {
          margin-left: 16px;
        }
      }
    }

    .no-policy {
      margin-bottom: 48px;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin-top: 0;
    }
  `,
)

export default Root
