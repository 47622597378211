import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing[4]}px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .upsell-share {
      &__button {
        max-width: 440px;
        width: 100%;
      }
      &__chat {
        margin: 0 ${theme.spacing[3]}px;
      }

      &__chat-wrapper {
        margin-top: 32px;
        display: flex;
      }

      &__icon {
        position: absolute;
        pointer-events: none;
        font-size: 1.5rem;
      }

      &__content {
        max-width: 440px;
        width: 100%;
        margin-top: ${theme.spacing[3]}px;
        padding: ${theme.spacing[4]}px;

        background: ${theme.palette.neutral[50]};

        &-header {
          margin-bottom: 12px;
          margin-left: 24px;
          display: flex;
          position: relative;

          > * + * {
            margin-left: ${theme.spacing[3]}px;
          }
        }

        &-description {
          display: inline-block;
          margin-bottom: ${theme.spacing[4]}px;
        }
      }

      &__form {
        > div + div {
          margin-top: ${theme.spacing[4]}px;
        }

        &-button {
          margin-top: 42px;
        }
      }

      &__referral {
        margin-top: ${theme.spacing[3]}px;
        max-width: 440px;
      }
    }
  `,
)
