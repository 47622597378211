import React from 'react'

import { Button, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { withModal } from '@azos/shared'
import { makeLoadBillingDateService } from '@main/factories/services'
import { useCheckout } from '@presentation/providers/CheckoutProvider'
import dayjs from 'dayjs'

import BillingDateCard from './BillingDateCard/BillingDateCard'
import { SetDueDateProps } from './SetDueDate.props'
import { Root } from './SetDueDate.styles'

const loadBillingDateService = makeLoadBillingDateService()

const SetDueDate: React.VFC<SetDueDateProps> = ({
  open,
  onClose,
  proposalId,
  onSubmit,
}) => {
  const [date, setDate] = React.useState<string>('')
  const [billingDate, setBillingDate] = React.useState<string>('')
  const [nextDueDate, setNextDueDate] = React.useState<string>('')
  const [visibleTextDate, setVisibleTextDate] = React.useState<string>('')

  const { expiresAt } = useCheckout()

  const validDates = [
    '3',
    '5',
    '7',
    '9',
    '10',
    '12',
    '15',
    '18',
    '20',
    '22',
    '25',
    '27',
  ]

  React.useEffect(() => {
    analytics.dashboard.upsell.checkout.viewDueDateSelectDrawer.execute()
  }, [])

  React.useEffect(() => {
    if (expiresAt) setBillingDate(expiresAt)
  }, [expiresAt])

  const onSelectBillingDay = async (day: string) => {
    const { nextDueDate } = await loadBillingDateService.execute({
      proposalId: proposalId as string,
      billingDay: +day,
    })

    analytics.dashboard.upsell.checkout.selectDueDate.execute()

    const formattedDate = dayjs(nextDueDate)
      .locale('pt-br')
      .format('DD [de] MMMM')
    const formattedNextDueDate = dayjs(nextDueDate)
      .locale('pt-br')
      .format('YYYY-MM-DD')

    setDate(day)
    setBillingDate(day)
    setVisibleTextDate(formattedDate)
    setNextDueDate(formattedNextDueDate)
  }

  const currentExpiresDate = (() => {
    const today = new Date()
    today.setDate(today.getDate() + 2)
    return dayjs(today).locale('pt-br').format('DD [de] MMMM')
  })()

  const onSubmitDrawer = () => {
    onSubmit(date, nextDueDate)
  }

  return (
    <Root
      variant="drawer"
      open={open}
      onClose={onClose}
      exitBgColor="#F3F4F6"
      maxWidth="445px"
    >
      <div className="change-due-date">
        <Typography variant="h4">
          Definir data de <br /> vencimento da apólice
        </Typography>
        <div className="change-due-date__numbers">
          {validDates?.map((d, index) => (
            <BillingDateCard
              key={index}
              originalDate={d}
              displayDates={Number(d)}
              active={billingDate === d}
              onClick={onSelectBillingDay}
            />
          ))}
        </div>
        <Typography variant="h6">Próxima fatura</Typography>
        <div className="change-due-date__options">
          <div className="change-due-date__options--current-date">
            <i className="icon-calendar" />
            <Typography variant="bodyBold3">{currentExpiresDate}</Typography>
          </div>
          <i className="icon-status-warning" />
          <Typography variant="caption">
            Essa fatura terá a data de vencimento atual
          </Typography>
        </div>
        {visibleTextDate.length !== 0 && (
          <div className="change-due-date__new-options">
            <div className="change-due-date__new-options--next-date">
              <i className="icon-calendar" />
              <Typography variant="bodyBold3">{visibleTextDate}</Typography>
            </div>
            <i className="icon-status-on" />
            <Typography variant="caption">
              A partir dessa fatura entrará em vigor a nova data
            </Typography>
          </div>
        )}
      </div>

      <Button
        fullWidth
        disabled={!billingDate}
        onClick={onSubmitDrawer}
        className="change-due-date__button"
      >
        Salvar alteração
      </Button>
    </Root>
  )
}

export default withModal(SetDueDate)
