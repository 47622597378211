import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    width: 100%;

    button {
      margin-bottom: 32px;

      :hover {
        color: ${theme.palette.neutral[300]};
      }
    }
  `,
)

export default Root
