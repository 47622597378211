import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { Utils } from '@azos/core'
import {
  Input,
  InputCardDate,
  InputCreditCard,
  InputCVV,
} from '@azos/shared/src/components/v2'
import creditCards from '@presentation/assets/icons/creditCards.svg'
import creditCardType from 'credit-card-type'
import { useFormikContext } from 'formik'
import Image from 'next/image'

import { FormCreditcardValue } from '../../../Checkout.props'
import { Root } from './CreditCardForm.styles'

const CreditCardForm: React.VFC = () => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    isValid,
    setTouched,
    setFieldValue,
  } = useFormikContext<FormCreditcardValue>()

  React.useEffect(() => {
    setTouched({})
  }, [setTouched])

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting && isValid,
    [isSubmitting, isValid],
  )

  const [creditCardBrand, setCreditCardBrand] = React.useState<string | null>(
    null,
  )

  return (
    <Root>
      <div className="credit-card-form__credit-cards-content">
        <Typography
          variant="caption"
          className="credit-card-form__credit-cards-title"
        >
          Bandeiras aceitas:
        </Typography>
        <Image src={creditCards} width="140px" height="22px" />
      </div>
      <InputCreditCard
        autoFocus
        id="ccnumber"
        name="ccnumber"
        value={values.ccnumber}
        onBlur={handleBlur}
        mask={Utils.payment.getMaskByBrand(creditCardBrand)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          const value = e.target.value
          const brand = creditCardType(value)?.[0]?.type
          setCreditCardBrand(value ? brand : null)
        }}
        placeholder=""
        autoComplete="off"
        error={touched.ccnumber && !!errors.ccnumber}
        helperText={touched.ccnumber && errors.ccnumber}
        disabled={isDisabled}
        fullWidth
      />
      <Input
        id="ccname"
        name="ccname"
        label="Nome completo"
        value={values.ccname || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder=""
        autoComplete="off"
        error={touched.ccname && !!errors.ccname}
        helperText={touched.ccname && errors.ccname}
        disabled={isDisabled}
        fullWidth
      />
      <div className="credit-card-form__input-wrapper">
        <InputCardDate
          id="exp_date"
          name="exp_date"
          value={values.exp_date}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder=""
          autoComplete="off"
          error={touched.exp_date && !!errors.exp_date}
          helperText={touched.exp_date && errors.exp_date}
          disabled={isDisabled}
          fullWidth
        />
        <InputCVV
          id="cvv"
          name="cvv"
          value={values.cvv}
          onBlur={handleBlur}
          onChange={event =>
            setFieldValue('cvv', event.target.value.replace(/\D/g, ''))
          }
          placeholder=""
          autoComplete="off"
          error={touched.cvv && !!errors.cvv}
          helperText={touched.cvv && errors.cvv}
          disabled={isDisabled}
          fullWidth
        />
      </div>
    </Root>
  )
}

export default CreditCardForm
