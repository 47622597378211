import React from 'react'

import {
  Card,
  CardActions,
  CardHeader,
  CardTitle,
  Toggle,
  Typography,
} from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { ModalCoverageDetails } from '@presentation/components/molecules/Coverages'

import { RefusedCardProductProps } from './RefusedCardProduct.props'
import Root from './RefusedCardProduct.styles'

const RefusedCoverages = ({
  icon,
  title,
  secondaryIcon,
  description,
  code,
  label,
  reason,
}: RefusedCardProductProps) => {
  const onIconClick = () => {
    setOpenModalCoverage(true)
    analytics.dashboard.upsell.calculator.clickHelpCoverage.execute({
      type: title,
    })
  }
  const [openModalCoverage, setOpenModalCoverage] = React.useState(false)

  return (
    <Root>
      <Card className="refused-card-product">
        <CardHeader>
          <CardTitle
            icon={icon as string}
            title={title}
            className="refused-card-product__title"
          >
            <CardActions>
              <i
                className={`${
                  secondaryIcon ?? 'icon-help'
                } refused-card-product__icon`}
                onClick={onIconClick}
              />
            </CardActions>
          </CardTitle>
        </CardHeader>

        <hr />

        <div className="refused-card-product__description">
          <Typography
            className="refused-card-product__description--text"
            variant="body3"
          >
            {description}
          </Typography>
          <Toggle
            className="refused-card-product__description--toggle"
            id={code}
            disabled
            label={label ?? 'Incompatível'}
          />
        </div>

        <div className="refused-card-product__alert">
          <Typography tag="i" className="icon-circle-information" />
          <Typography variant="body3">{reason}</Typography>
        </div>
      </Card>
      <ModalCoverageDetails
        open={openModalCoverage}
        onClose={() => setOpenModalCoverage(false)}
        code={code}
        canAddCoverage={false}
      />
    </Root>
  )
}

export default RefusedCoverages
