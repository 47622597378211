import React from 'react'

import { SignInV2, useAuth } from '@azos/account'
import { NoSSR } from '@azos/shared'
import { Header } from '@azos/shared/src/components/v2'
import { useRouter } from 'next/router'

import { Root } from './Login.styles'

const LoginPage = () => {
  const { query } = useRouter()
  const { setFrom } = useAuth()

  React.useEffect(() => {
    if (query.origin) {
      setFrom(query.origin.toString())
    }
  }, [query.origin, setFrom])

  return (
    <NoSSR>
      <Root>
        <Header hiddenSignIn />
        <main>
          <div className="card">
            <SignInV2 />
          </div>
        </main>
      </Root>
    </NoSSR>
  )
}

export default LoginPage
