import { css, styled, Modal } from '@azos/shared'

export const Root = styled(Modal)(
  () => css`
    .modal-content {
      .modal-content-wrapper {
        margin: 30px;
      }
    }

    .change-due-date {
      &__button {
        margin-bottom: 40px;
      }

      &__numbers {
        display: grid;
        grid-template-columns: 48px 48px 48px 48px;
        gap: 36px 59px;
        margin-top: 46px;
        min-width: 370px;
      }

      h6 {
        margin: 68px 0 36px 0;
        font-size: 18px;
      }

      &__options {
        background: #f3f4f6;
        padding: 16px;

        i {
          margin-right: 8px;
          color: #ff455b;
        }

        &--current-date {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          i {
            margin-right: 8px;
            font-size: 24px;
            color: #090a0b;
          }
        }
      }

      &__new-options {
        padding: 16px;

        i {
          margin-right: 8px;
          color: #50c309;
        }
        &--next-date {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          i {
            margin-right: 8px;
            font-size: 24px;
            color: #090a0b;
          }
        }
      }
    }
    button {
      margin-top: 53px;
    }
  `,
)
