import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    width: 100%;
    max-width: 440px;

    @media (min-width: ${theme.breakpoints.md}px) {
      padding-top: 48px;
    }

    display: flex;
    justify-content: center;

    .insured-data {
      &__button {
        padding: 12px;
        height: 48px;
        border: none;
        background-color: ${theme.palette.neutral[100]};
        color: ${theme.palette.neutral[500]};

        &:first-of-type {
          margin-bottom: 24px;
        }
      }
    }
  `,
)

export default Root
