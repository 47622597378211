import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.md}px) {
      margin: 0;
      padding: 0;
    }

    .calculator {
      &__back-button {
        margin-bottom: ${theme.spacing[4]}px;
      }

      &__wrapper {
        display: grid;
        margin-bottom: 100px;

        @media (min-width: ${theme.breakpoints.md}px) {
          grid-template-columns: 1fr minmax(min-content, 295px);
          gap: 40px;
          padding: 0 80px 80px;
          margin-bottom: 0;
        }

        &--content {
          padding: 16px;
          margin-bottom: 60px;

          @media (min-width: ${theme.breakpoints.md}px) {
            padding: 0;
          }
        }
      }

      &__selected-coverages--title {
        display: inline-block;
        margin-bottom: 12px;
      }

      &__selected-coverages--product {
        display: flex;
        align-items: center;
        color: #505969;
        font-size: 14px;
        margin-bottom: 18px;

        i {
          font-size: 24px;
          margin-right: 10px;
        }
      }

      &__unselected-coverages {
        &.hasPrevious {
          margin-top: ${theme.spacing[6]}px;
        }
      }

      &__aside {
        position: sticky;
        bottom: 80px;
        left: 0;
        margin: 0;
        z-index: 1199;

        @media (min-width: 600px) {
          bottom: 0;
        }

        @media (min-width: ${theme.breakpoints.md}px) {
          max-width: 295px;
          width: 100%;
          height: fit-content;
          padding: 18px;
          position: inherit;
          background-color: #ffffff;
          margin-top: 80px;
        }

        &--accordion-content {
          margin-bottom: ${theme.spacing[4]}px;

          @media (min-width: 600px) {
            margin-top: ${theme.spacing[4]}px;
          }

          @media (min-width: ${theme.breakpoints.md}px) {
            margin-top: ${theme.spacing[5]}px;
            margin-bottom: 0;
          }
        }

        &--description {
          margin-top: ${theme.spacing[3]}px;
          display: flex;
          align-items: center;

          span {
            color: ${theme.palette.neutral[400]};
          }

          i {
            font-size: 1.25rem;
            color: ${theme.palette.neutral[400]};
            margin-right: ${theme.spacing[2]}px;
          }
        }

        &--icon {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          span {
            font-weight: 600;
          }

          i {
            font-size: 24px;
            color: #a3aab9;
            margin-right: 12px;
          }
        }

        &--info {
          display: flex;
          align-items: center;
          margin-bottom: ${theme.spacing[3]}px;

          @media (min-width: 600px) {
            margin-bottom: 0;
          }

          p {
            color: #a3aab9;
            font-size: 14px;
          }

          strong {
            padding: 0 10px;
          }
        }

        &--coverage-item {
          &:not(:first-of-type) {
            margin-top: ${theme.spacing[3]}px;
          }

          display: flex;
          justify-content: space-between;
          align-items: center;

          &-value {
            margin-right: ${theme.spacing[4]}px;
          }

          &-description {
            display: block;
            color: ${theme.palette.neutral[400]};
            white-space: nowrap;
          }
        }

        button {
          @media (min-width: ${theme.breakpoints.md}px) {
            margin-top: 32px;
          }

          padding: 12px;
          margin: 12px 0 0 0;
        }
      }
    }
  `,
)
