import { User } from '@azos/account'
import { HttpClient, makeAxiosHttpClient } from '@azos/core'

export type SetScorePayload = {
  sessionId: string
  proposalId: string
  user: User | null
}

const ClearsaleAPI = (httpClient: HttpClient = makeAxiosHttpClient()) => {
  return {
    setScore: (payload: SetScorePayload): Promise<any> => {
      return httpClient
        .request({
          url: `/clearsale`,
          method: 'post',
          body: payload,
        })
        .then(response => response.data)
        .catch(() => null)
    },
  }
}

export default ClearsaleAPI
