import React from 'react'

import { Button } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { SelectContract } from '@azos/analytics/src/domain/models'
import { ChatAvatarCard } from '@azos/shared'
import { useRouter } from '@presentation/providers/RouterProvider'
import { useUpsell } from '@presentation/providers/UpsellProvider'

import { Root } from './InsuredData.styles'

const InsuredData = () => {
  const { user } = useAuth()
  const Router = useRouter()
  const { specialistUpsell } = useUpsell()

  React.useEffect(() => {
    analytics.dashboard.upsell.contract.viewSelectPage.execute()
  }, [])

  const buttons = [
    {
      buttonName: 'Para mim',
      onClick: () => {
        analytics.dashboard.upsell.contract.selectContractType.execute({
          type: SelectContract.ME,
        })
        Router.push('/simular-novo-seguro/simulacao')
      },
    },
    {
      buttonName: 'Para outra pessoa',
      onClick: () => {
        analytics.dashboard.upsell.contract.selectContractType.execute({
          type: SelectContract.OTHER,
        })
        Router.push('/simular-novo-seguro/indicar')
      },
    },
  ]

  const renderButton = buttons.map(
    button =>
      (
        <Button
          className="insured-data__button"
          key={button.buttonName}
          onClick={button.onClick}
          variant="primary"
        >
          {button.buttonName}
        </Button>
      ) as React.RefAttributes<HTMLButtonElement[]>,
  )

  return (
    <Root>
      <ChatAvatarCard
        title={`Olá, ${user?.displayName}!`}
        subtitle="Que bom ter você por aqui"
        description="A contratação do seguro é para você?"
        buttons={renderButton}
        specialist={specialistUpsell}
      />
    </Root>
  )
}

export default InsuredData
