import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { useMediaQuery } from '@azos/shared'
import { WizardList } from '@data/models'
import { PoliciesResponse } from '@domain/models'
import { OnboardingManagement } from '@presentation/components/organisms/OnboardingManagement'
import TemplateManager from '@presentation/components/templates/TemplateManager'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'
import { useRouter } from '@presentation/providers/RouterProvider'

import { OnboardingManagementCard } from './components'
import { Root } from './Onboarding.styles'

type MyPoliciesProps = {
  policies: PoliciesResponse[]
  wizardList: WizardList | null
}

const Onboarding: React.VFC<MyPoliciesProps> = ({
  wizardList: wizardData,
  policies,
}) => {
  const { user } = useAuth()
  const { setWizard } = useOnboardingWizard()
  const { isMobile } = useMediaQuery()
  const Router = useRouter()
  const { setPolicies } = useMyPolicies()

  React.useEffect(() => {
    setPolicies(policies)
  }, [policies, setPolicies])

  React.useEffect(() => {
    if (wizardData) {
      setWizard(wizardData)
    }
  }, [wizardData, setWizard])

  React.useEffect(() => {
    analytics.dashboard.onboarding.viewOnboardingPage.execute()
  }, [])

  const handleClose = async () => {
    await Router.push('/inicio')
  }

  if (isMobile) {
    return (
      <OnboardingManagementCard
        preventScroll={false}
        open
        onClose={handleClose}
      />
    )
  }

  return (
    <TemplateManager headerProps={{ user }}>
      <Root>
        <OnboardingManagement />
      </Root>
    </TemplateManager>
  )
}

export default Onboarding
