import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { ClickPoliciesTab } from '@azos/analytics/src/domain/models'
import { NavigationTabs, useMediaQuery } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { WizardList } from '@data/models'
import { PoliciesResponse, ProposalsResponse } from '@domain/models'
import { MENU } from '@main/config/menu'
import { PoliciesList } from '@presentation/components/molecules/Policies'
import { ProposalsList } from '@presentation/components/molecules/Proposals'
import TemplateManager from '@presentation/components/templates/TemplateManager'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'
import { NextPage } from 'next'

import Root from './MyPolicies.styles'

type MyPoliciesProps = {
  proposals: ProposalsResponse[]
  policies: PoliciesResponse[]
  wizardList: WizardList | null
}

const MyPolicies: NextPage<MyPoliciesProps> = ({
  proposals,
  policies: policiesData,
  wizardList: wizardData,
}) => {
  const { policies, setPolicies } = useMyPolicies()
  const { setWizard } = useOnboardingWizard()
  const { isMobile } = useMediaQuery()
  const { user } = useAuth()

  React.useEffect(() => {
    setPolicies(policiesData)
  }, [policiesData, setPolicies])

  React.useEffect(() => {
    analytics.dashboard.policy.view.policyScreenView.execute()
  }, [])

  React.useEffect(() => {
    if (wizardData) {
      setWizard(wizardData)
    }
  }, [wizardData, setWizard])

  const handleTabClick = (index: string) => {
    const tabIndexCases = {
      0: ClickPoliciesTab.POLICIES,
      1: ClickPoliciesTab.PROPOSALS,
    }

    if (tabIndexCases[index])
      analytics.dashboard.policies.clickPoliciesTabs.execute({
        tab: tabIndexCases[index],
      })
  }

  return (
    <TemplateManager
      headerProps={{ user }}
      navigationProps={{ value: MENU.MINHAS_APOLICES }}
      headerContent={
        <Root>
          <div className="my-policies__header">
            <i className="icon-policy" />
            <Text variant="h5" className="my-policies__header--title">
              Suas apólices
            </Text>
            <Text variant="body3" className="my-policies__header--description">
              Aqui ficam as suas apólices e propostas.
            </Text>
          </div>
        </Root>
      }
    >
      <Root>
        {isMobile && (
          <NavigationTabs
            ariaLabel=""
            content={[
              <PoliciesList key={0} policies={policies} />,
              <ProposalsList key={1} proposals={proposals} />,
            ]}
            contentWrapper={children => (
              <div className="my-policies__mobile-list-group">{children}</div>
            )}
            tabs={[
              `Apólices (${policies.length})`,
              `Propostas (${proposals.length})`,
            ]}
            onTabClick={handleTabClick}
          />
        )}
        {!isMobile && (
          <div className="my-policies__list-group">
            <PoliciesList policies={policies} showHeader />
            <ProposalsList proposals={proposals} showHeader />
          </div>
        )}
      </Root>
    </TemplateManager>
  )
}

export default MyPolicies
