import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .onboarding-card-container {
      &__actions {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
        padding: ${theme.spacing[5]}px ${theme.spacing[3]}px
          ${theme.spacing[3]}px;
        background: ${theme.palette.neutral[100]};

        @media (min-width: ${theme.breakpoints.md}px) {
          padding: 0;
          margin-top: 28px;
          grid-template-columns: repeat(2, 1fr);
          gap: ${theme.spacing[4]}px;
        }
      }
    }
  `,
)
