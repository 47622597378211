import React from 'react'

import { Box, Skeleton, Typography } from '@Azos-Seguros/apollo'
import { Utils } from '@azos/shared'
import {
  Button,
  CardCopyText,
  CardCopyTextHandle,
  CardQRCode,
} from '@azos/shared/src/components/v2'

import { PAYMENT_STATUS } from '../../Checkout.props'
import { PixProps } from './Pix.props'
import { Root } from './Pix.styles'

const Pix: React.VFC<PixProps> = ({ isLoading, pix, status }) => {
  const copyRef = React.useRef<CardCopyTextHandle>(null)

  const handleButtonText = () => {
    const statusCases: { [key: string]: string } = {
      [PAYMENT_STATUS.paid]: 'Continuar',
      [PAYMENT_STATUS.timeout]: 'Pagamento não identificado',
      default: 'Aguardando o pagamento...',
    }

    return statusCases[status] ?? statusCases.default
  }

  return (
    <Root>
      <Typography className="pix__title" variant="bodyBold3">
        Sem camêra:
      </Typography>
      <Typography className="pix__description" variant="body3">
        Selecione tipo chave <strong>aleatória</strong>, copie e cole o código
        abaixo.
      </Typography>

      <div className="pix__copy-card-content">
        {isLoading ? (
          <Skeleton
            text="Gerando código, enquanto isso prepare para abrir o app do banco..."
            height="64"
          />
        ) : (
          <>
            <CardCopyText
              ref={copyRef}
              value={pix?.line || ''}
              className="pix__copy-card-content--text-content"
            />
            <Button
              className="pix__copy-card-content--copy-button"
              fullWidth
              variant="outline"
              title="Agora não"
              type="button"
              onClick={() => {
                copyRef.current?.handleCopy()
              }}
            >
              Copiar o código
            </Button>
          </>
        )}
      </div>

      <Typography className="pix__title" variant="bodyBold3">
        Com camêra:
      </Typography>
      <Typography className="pix__description" variant="body3">
        No aplicativo do pagamento ou banco, aponte sua camera no QR Code
        abaixo.
      </Typography>

      <CardQRCode
        className="pix__card-qr-code"
        loading={isLoading}
        value={pix?.line || ''}
        skeletonProps={{
          text: 'Gerando QR Code, enquanto isso prepare abrir o app do banco...',
        }}
      />

      {status === PAYMENT_STATUS.timeout && (
        <Box className="pix__timeout-content">
          <i className="icon-circle-information" />
          <Box>
            <Typography variant="body2" className="pix__timeout-content--text">
              Não identificamos seu pagamento.
            </Typography>
            <Typography variant="body3" className="pix__timeout-content--text">
              Também enviamos a fatura PIX em seu e-mail , em nome de nosso
              parceiro, Excelsior Seguros. Caso tenha efetuado o pagamento,
              desconsidere este aviso.
            </Typography>
            <Typography variant="body3" className="pix__timeout-content--text">
              <strong>
                Pague até o dia : {Utils.date.formatDate(pix?.due ?? '')}
              </strong>
            </Typography>
          </Box>
        </Box>
      )}

      <Button
        className="pix__submit-button"
        fullWidth
        type="submit"
        disabled={status !== PAYMENT_STATUS.paid}
      >
        {handleButtonText()}
      </Button>
    </Root>
  )
}

export default Pix
