import React from 'react'

import { Tooltip, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { SetDueDate } from '@presentation/components/molecules/SetDueDate'
import { useCheckout } from '@presentation/providers/CheckoutProvider'

import { DueDateProps } from './DueDate.props'
import { Root } from './DueDate.styles'

const DueDate: React.VFC<DueDateProps> = ({ proposalId, onSubmit }) => {
  const [isChangeDueDate, setIsChangeDueDate] = React.useState(false)
  const { expiresAt } = useCheckout()

  const onDueDateClick = () => {
    analytics.dashboard.upsell.checkout.clickInsertDueDate.execute()
    setIsChangeDueDate(true)
  }

  const onSubmitDate = (date: string, nextDueDate: string) => {
    onSubmit(date, nextDueDate)
    setIsChangeDueDate(false)
  }

  return (
    <Root $hasExpiresAt={!!expiresAt}>
      <div className="duedate-card">
        <i className="icon-calendar" />
        <div className="duedate-card__title">
          <Typography>
            <strong>Data de vencimento </strong>(opcional)
          </Typography>
          <Tooltip
            position="top-start"
            title="Se desejar, você pode definir a sua data de vencimento
        depois. Essa definição traz mais controle financeiro e
        tranquilidade."
          >
            <i className="icon-circle-help" />
          </Tooltip>
        </div>

        <div
          className="duedate-card__textField"
          role="button"
          onClick={onDueDateClick}
        >
          <Typography
            variant="bodyBold3"
            className="duedate-card__textField-text"
          >
            {expiresAt ? `Todo dia ${expiresAt} do mês` : 'Selecionar data'}
          </Typography>
          <i className="icon-calendar" />
        </div>
      </div>

      <SetDueDate
        open={isChangeDueDate}
        onClose={() => setIsChangeDueDate(false)}
        proposalId={proposalId}
        onSubmit={onSubmitDate}
      />
    </Root>
  )
}

export default DueDate
