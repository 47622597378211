import {
  css,
  Modal as SharedModal,
  ModalProps as SharedModalProps,
} from '@azos/shared'
import styled from '@emotion/styled'

export const Modal = styled(SharedModal)<SharedModalProps>(
  ({ theme }) => css`
    .onboarding-management-card {
      &__header {
        display: flex;
        align-items: center;

        background: ${theme.palette.neutral[400]};
        padding: 18px;
        position: sticky;
        top: 0;
        z-index: ${theme.zIndex.header};

        &--title {
          margin-left: 18px;
          color: ${theme.palette.neutral[50]};
        }
      }
    }
    .modal-content {
      width: 100%;

      .modal-content-wrapper {
        margin: 0;
        position: relative;
      }
    }

    .exit-button-modal {
      top: 24px;
      right: 24px;

      cursor: pointer;
      background: transparent;
      border: 0;
      font-size: 24px;

      padding: 0;
      height: 24px;
      width: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: ${theme.palette.neutral[50]};
        font-size: 24px;
      }
    }
  `,
)
