import React from 'react'

import { Button, Typography } from '@Azos-Seguros/apollo'
import { Modal } from '@azos/shared'
import { CallCenterModal } from '@presentation/components/molecules/CallCenterModal'

import { UpsellConfirmModalProps } from './UpsellConfirmModal.props'
import Root from './UpsellConfirmModal.styles'

const UpsellConfirmModal: React.FC<UpsellConfirmModalProps> = ({
  open,
  onClose,
}) => {
  const [isShowingCallCenterModal, setIsShowingCallCenterModal] =
    React.useState(false)

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeOnOverlayClick={false}
        maxWidth="464px"
        showHeader={false}
      >
        <Root>
          <Typography variant="h4" className="title">
            Identificamos que você já possui uma apólice com a Azos
          </Typography>

          <Typography className="description">
            As suas coberturas podem ter novos limites. Mas não se preocupe!
            Inicie uma nova simulação em sua conta ou conte com nosso
            atendimento.
          </Typography>

          <Button onClick={onClose} className="button">
            Iniciar simulação
          </Button>
          <Button
            onClick={() => setIsShowingCallCenterModal(true)}
            variant="outline"
          >
            Fale com atendimento
          </Button>
        </Root>
      </Modal>
      <CallCenterModal
        open={isShowingCallCenterModal}
        onClose={() => setIsShowingCallCenterModal(false)}
      />
    </>
  )
}

export default UpsellConfirmModal
