import React from 'react'

import { Text } from '@azos/shared/src/components/v2'
import { OnboardingManagement } from '@presentation/components/organisms/OnboardingManagement'

import { OnboardingManagementCardProps } from './OnboardingManagementCard.props'
import { Modal } from './OnboardingManagementCard.styles'

const OnboardingManagementCard: React.VFC<OnboardingManagementCardProps> = ({
  onClose,
  ...rest
}) => {
  return (
    <Modal {...rest} onClose={onClose} variant="drawer" showHeader={false}>
      <div className="onboarding-management-card__header">
        <button type="button" onClick={onClose} className="exit-button-modal">
          <i className="icon-arrow-left" />
        </button>
        <Text
          className="onboarding-management-card__header--title"
          variant="bodyBold"
        >
          Tudo sobre o seu seguro
        </Text>
      </div>
      <OnboardingManagement />
    </Modal>
  )
}

export default OnboardingManagementCard
