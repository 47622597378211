import { css, Utils } from '@azos/shared'
import { Badge, BadgeProps } from '@azos/shared/src/components/v2'
import styled from '@emotion/styled'

type RootProps = {
  $backgroundColor: string
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $backgroundColor }) => css`
    .card-content {
      cursor: pointer;
      width: 100%;
      height: 100%;
      background: ${theme.palette.neutral[50]};
      margin-bottom: ${theme.spacing[3]}px;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      @media (min-width: ${theme.breakpoints.md}px) {
        min-height: 147px;
      }

      &__img {
        border-radius: 8px 0 0 0px;
        width: 100%;
        height: 88px;
        background: ${$backgroundColor};
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @media (min-width: 768px) {
          width: 200px;
          height: auto;

          img {
            object-fit: contain;
          }
        }
      }

      &__text {
        padding: ${theme.spacing[3]}px;
        flex: 1;

        &--title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h5 {
            margin: 0;
          }

          i {
            color: ${theme.palette.neutral[500]};
          }
        }

        h6 {
          margin: 10px 0px 12px 0px;
          font-weight: ${theme.typography.fontWeightRegular};
        }

        p {
          font-size: 14px;
          color: ${theme.palette.neutral[400]};
          margin-bottom: 0;
        }
      }
    }
  `,
)

export const BadgeContent = styled(Badge)<BadgeProps>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing[2]}px;
    width: fit-content;

    i {
      font-size: 0.875rem;
      margin-right: 4px;
    }

    font-weight: 700;
  `,
)
