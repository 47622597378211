import React from 'react'

import {
  Card,
  CardActions,
  CardHeader,
  CardTitle,
  Slider,
  Toggle,
  Typography,
} from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { Utils as CoreUtils } from '@azos/core'
import { useTheme, Utils } from '@azos/shared'
import { EditableInput } from '@azos/shared/src/components/v2'
import { ModalCoverageDetails } from '@presentation/components/molecules/Coverages'

import { CardProductProps } from './CardProduct.props'
import Root from './CardProduct.styles'

const CardProduct: React.FCC<CardProductProps> = ({
  name,
  description,
  icon,
  code,
  source,
  value,
  disabled,
  onChange,
  onChangeCommitted,
  onToggle,
}) => {
  const theme = useTheme()

  const [openModalCoverage, setOpenModalCoverage] = React.useState(false)

  const range = React.useMemo<{ min: number; max: number }>(() => {
    const min = source[0].premium
    const max = source[source.length - 1].premium
    return { min, max }
  }, [source])

  const selectedValue = React.useMemo<number>(() => value.premium, [value])
  const getPremium = Utils.number.getNearestValue(source.map(i => i.premium))

  const handleChange = React.useCallback(
    (inputValue: string | number) => {
      const selectedPremium = getPremium(Number(inputValue))
      const selected = source.find(item => item.premium === selectedPremium)
      if (selected) {
        onChange(selected)
      }
    },
    [getPremium, onChange, source],
  )

  const handleMouseUp = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      const selected = source.find(
        item => item.premium === Number(event.currentTarget.value),
      )
      if (selected) {
        onChangeCommitted?.(selected)
      }
    },
    [onChangeCommitted, source],
  )

  const handleChangeInput = React.useCallback(
    (inputValue: number) => {
      const valueData = Array.isArray(inputValue) ? inputValue[0] : inputValue
      handleChange(valueData)
    },
    [handleChange],
  )

  const onModalClick = () => {
    analytics.dashboard.upsell.calculator.clickCoverageDetails.execute()
  }

  return (
    <Root>
      <Card shadow="100" className="card-product">
        <CardHeader>
          <CardTitle
            icon={icon as string}
            title={name}
            className="card-product__title"
          >
            <CardActions>
              <Toggle
                className="card-product__description--toggle"
                id={code}
                checked
                onChange={() => onToggle(code)}
              />
            </CardActions>
          </CardTitle>
        </CardHeader>
        <hr />
        <div className="card-product__description">
          <div>
            <Typography
              className="card-product__description--text"
              variant="body3"
            >
              {description}
            </Typography>
            <button
              className="card-product__description--modal-button"
              onClick={onModalClick}
            >
              <Typography
                className="card-product__description--modal-text"
                variant="body3"
                onClick={() => setOpenModalCoverage(true)}
              >
                Entenda mais
                <i className="icon-chevron-right" />
              </Typography>
            </button>
          </div>

          {!CoreUtils.coverages.isFuneralAssistanceCoverage(code) && (
            <div>
              <Typography
                className="card-product__slider-text"
                variant="bodyBold3"
              >
                Valor da cobertura:
              </Typography>

              <EditableInput
                value={selectedValue}
                onChange={handleChangeInput}
                onFocus={() =>
                  analytics.dashboard.upsell.calculator.clickCoverageValueInput.execute()
                }
                fullWidth
                className="card-product__slider-input"
              />

              <Slider
                className="card-product__slider"
                min={range.min}
                max={range.max}
                value={selectedValue}
                disabled={disabled}
                onChange={event => handleChange(event.target.value)}
                onMouseUp={handleMouseUp}
                minLabel={Utils.currency.getLongValue(range.min)}
                maxLabel={Utils.currency.getLongValue(range.max)}
                color={theme.palette.primary.main}
              />
            </div>
          )}
        </div>
      </Card>
      <ModalCoverageDetails
        open={openModalCoverage}
        onClose={() => setOpenModalCoverage(false)}
        code={code}
        canAddCoverage={false}
      />
    </Root>
  )
}

export default CardProduct
