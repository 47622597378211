import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { Utils as CoreUtils } from '@azos/core'
import { getCoverageIcon } from '@main/config/coverages'
import { useCalculator } from '@presentation/providers/CalculatorProvider'

import { UnselectedCardProduct } from '../UnselectedCardProduct'
import { UnselectedCoveragesProps } from './UnselectedCoverages.props'
import Root from './UnselectedCoverages.styles'

const UnselectedCoverages: React.VFC<UnselectedCoveragesProps> = ({
  coverages,
  totalCoveragesSelected,
  onSelectCoverage,
  className,
}) => {
  const { selectedCoverages } = useCalculator()
  const isAllCoveragesUnselected = totalCoveragesSelected === 0

  const renderCoverages = coverages?.map(coverage => {
    const icon = getCoverageIcon(coverage.code)
    const isEmpty = selectedCoverages.length === 0
    const isFuneral = CoreUtils.coverages.isFuneralAssistanceCoverage(
      coverage.code,
    )

    return (
      <div className="unselected-coverages__coverages" key={coverage.info.slug}>
        <UnselectedCardProduct
          icon={icon}
          title={coverage.info.name}
          description={coverage.info.description}
          disabled={isEmpty && isFuneral}
          code={coverage.code}
          onSelectCoverage={() => onSelectCoverage(coverage)}
        />
      </div>
    )
  })

  return (
    <Root className={className}>
      <div className="unselected-coverages">
        <div className="unselected-coverages-content">
          <div className="unselected-coverages-content__icon">
            <i className="icon-policy-doubt" />
          </div>
          <div className="unselected-coverages-content__no-coverage">
            <Typography
              variant="bodyBold"
              className="unselected-coverages-content__no-coverage--title"
            >
              {isAllCoveragesUnselected
                ? 'Sem cobertura selecionada'
                : 'Adicione outras coberturas'}
            </Typography>
            {isAllCoveragesUnselected && (
              <Typography
                variant="body2"
                className="unselected-coverages-content__no-coverage--subtitle"
              >
                Vamos se proteger mais? <br /> Selecione os produtos abaixo para
                calcular.
              </Typography>
            )}
          </div>
        </div>
        {renderCoverages}
      </div>
    </Root>
  )
}

export default UnselectedCoverages
