import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    background: #fff;
    min-height: 100vh;

    @media (min-width: 1024px) {
      background: url('/assets/jpg/login_bg.jpg') no-repeat;
      background-size: cover;
    }

    > header {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    }

    > main {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 1200px;
      margin: 16px auto 0;

      @media (min-width: 768px) {
        margin: 0 auto;
        padding: 0 42px;
        min-height: calc(100vh - 64px);
      }
    }

    .card {
      background: #fff;
      padding: 32px;
      width: 100%;

      @media (min-width: 768px) {
        padding: 40px;
      }

      @media (min-width: 1024px) {
        width: 465px;
      }

      @media (min-width: 1200px) {
        width: 608px;
      }

      h1 {
        font-size: 32px;
      }

      a {
        display: inline-block;
        ${theme.typography.bodyBold3};
      }

      .MuiInput-underline {
        &:before {
          border-bottom-color: #d1d4dc;
        }
      }

      @media (max-width: 1200px) {
        > div:last-child {
          display: block;
          width: 100%;

          button {
            width: 100%;
            display: block;
            margin: 0;

            & + button {
              margin-top: 18px;
            }
          }
        }
      }
    }
  `,
)

export default Root
