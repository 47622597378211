import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[50]};
    height: 100%;

    @media (min-width: ${theme.breakpoints.md}px) {
      background: transparent;
    }

    main {
      display: flex;
      align-items: center;
      justify-content: center;

      max-width: 1200px;
      margin: ${theme.spacing[3]}px auto 0;

      @media (min-width: ${theme.breakpoints.md}px) {
        margin: 0 auto;
        padding: 0 42px;
        min-height: calc(100vh - 64px);
      }
    }

    .card {
      background: ${theme.palette.neutral[50]};
      padding: ${theme.spacing[5]}px;
      width: 100%;

      @media (min-width: ${theme.breakpoints.md}px) {
        padding: ${theme.spacing[6]}px;
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        width: 608px;
      }
    }
  `,
)
