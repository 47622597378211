import { PaymentMethod } from '@azos/core'
import { FinalQuotations } from '@data/models'
import { IGetAvailableDiscountService } from '@services/discounts/discount.service'
import { GetDiscountByProposalUseCase } from '@services/discounts/usecases'

import { ICreditCardValidator } from './components'

export type FormCreditcardValue = {
  ccnumber: string
  ccname: string
  exp_date: string
  cvv: string
}

export type CheckoutPageProps = {
  finalQuotations?: FinalQuotations | null
  proposalId: string
  discount?: GetDiscountByProposalUseCase.Response | null
  isWebview: boolean
}

export enum PAYMENT_STATUS {
  blocked,
  error,
  paid,
  processing,
  timeout,
}

export type CheckoutProps = CheckoutPageProps & {
  creditCardValidator?: ICreditCardValidator
  getAvailableDiscountService: IGetAvailableDiscountService
}

export type FormValue = Partial<FormCreditcardValue> & {
  paymentMethod: PaymentMethod
}

export type CheckoutStatusProps = Partial<{
  onBack: () => void
  onCopy: () => void
  onDone: () => void
  onReset: () => void
  onRetry: () => void
  proposalId: string
}>

export const INITIAL_VALUES: FormValue = {
  paymentMethod: PaymentMethod.CREDIT_CARD,
  ccnumber: '',
  ccname: '',
  exp_date: '',
  cvv: '',
}
