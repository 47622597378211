const profession = [
  {
    id: 1,
    description: 'Designer',
  },
  {
    id: 2,
    description: 'Engenheiro',
  },
]

export { profession }
