import React from 'react'

import { useAuth } from '@azos/account'
import { useUpsellRedirect } from '@presentation/hooks/useUpsellRedirect'
import { useRouter } from 'next/router'

import { InsuredData } from './components/InsuredData'
import { UpsellConfirmModal } from './components/UpsellConfirmModal'
import { Root } from './Upsell.styles'

const Upsell = () => {
  const { user, isLoading } = useAuth()
  const router = useRouter()

  const { dismissRedirect, isRedirectedFromSimulation } = useUpsellRedirect({})

  const [isShowingUpsellConfirmModal, setIsShowingUpsellConfirmModal] =
    React.useState(false)

  React.useEffect(() => {
    if (user && !user.isInsured && !isLoading) {
      router.replace('/inicio')
    }
  }, [router, user, isLoading])

  React.useEffect(() => {
    if (isRedirectedFromSimulation) {
      setIsShowingUpsellConfirmModal(true)
    }
  }, [isRedirectedFromSimulation])

  const handleDissmissUpsellConfirmModal = () => {
    dismissRedirect()
    setIsShowingUpsellConfirmModal(false)
  }

  return (
    <Root>
      <InsuredData />
      <UpsellConfirmModal
        open={isShowingUpsellConfirmModal}
        onClose={handleDissmissUpsellConfirmModal}
      />
    </Root>
  )
}

export default Upsell
