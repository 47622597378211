import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    > div + div {
      margin-top: ${theme.spacing[3]}px;
    }

    .credit-card-form {
      &__credit-cards {
        &-content {
          display: flex;
          align-items: center;
        }

        &-title {
          color: ${theme.palette.neutral[300]};
          margin-right: 12px;
        }
      }

      &__input-wrapper {
        display: flex;

        & > * {
          flex: 1;
        }

        & > * + * {
          margin-left: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)
