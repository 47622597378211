import React from 'react'

import analytics from '@azos/analytics'
import { useMediaQuery } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { ListBenefitsUseCase } from '@domain/usecases'
import { MENU } from '@main/config/menu'
import CardBenefits from '@presentation/components/molecules/CardBenefits'
import TemplateManager from '@presentation/components/templates/TemplateManager'

import Root, { BenefitsDivider } from './Benefits.styles'

export type BenefitsProps = {
  benefits: ListBenefitsUseCase.Response
}

const Benefits: React.FC<BenefitsProps> = ({ benefits }) => {
  const { isMobile } = useMediaQuery()

  React.useEffect(() => {
    analytics.dashboard.benefits.view.benefitsPageView.execute()
  }, [])

  return (
    <TemplateManager overflowMain navigationProps={{ value: MENU.BENEFICIOS }}>
      <Root>
        <Text variant="h5" className="benefits__title">
          Benefícios Azos
        </Text>

        <Text className="benefits__description">
          Quem faz parte da comunidade Azos possui benefícios em vida.
          Aproveite!
        </Text>

        {isMobile && <BenefitsDivider />}

        {benefits.length > 0 &&
          benefits.map(dataBenefit => (
            <CardBenefits key={dataBenefit.id} {...dataBenefit} />
          ))}

        {benefits.length === 0 && (
          <Text variant="h6" tag="p">
            Não há benefícios disponíveis no momento.
          </Text>
        )}
      </Root>
    </TemplateManager>
  )
}

export default Benefits
