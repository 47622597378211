import { HttpClient, makeAxiosHttpClient } from '@azos/core'
import { QuotationEstimatedPremiumsItem } from '@domain/models'
import { GetProductInfoUseCase } from '@domain/usecases'

const QuotationsAPI = (httpClient: HttpClient = makeAxiosHttpClient()) => {
  return {
    getProductInfo: ({
      quotationId,
    }: GetProductInfoUseCase.Params): Promise<GetProductInfoUseCase.Response> => {
      return httpClient
        .request({
          url: `/quotations/${quotationId}/product`,
          method: 'get',
        })
        .then(response => response.data)
    },
    getQuotation: (
      quotationId: string,
    ): Promise<QuotationEstimatedPremiumsItem[]> => {
      return httpClient
        .request({
          url: `/quotations/${quotationId}/estimated-premiums`,
          method: 'get',
        })
        .then(response => response.data)
    },
  }
}

export default QuotationsAPI
