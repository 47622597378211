import { css, styled, Utils } from '@azos/shared'

type ActiveProps = {
  $active: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<ActiveProps>(
  ({ $active, theme }) => css`
    display: flex;

    .billing-date-card {
      text-align: center;
      width: 48px;
      height: 48px;
      border: 2px solid ${theme.palette.neutral[100]};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      cursor: pointer;
      margin: 0;
      padding: 0;
      background: ${$active
        ? theme.palette.primary.main
        : theme.palette.neutral[100]};

      &__text {
        color: ${$active ? theme.palette.neutral[50] : '#000000'};
      }
    }
  `,
)
