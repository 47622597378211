import React from 'react'

import {
  Card,
  CardActions,
  CardHeader,
  CardTitle,
  Toggle,
  Typography,
} from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { ModalCoverageDetails } from '@presentation/components/molecules/Coverages'

import { UnselectedCardProductProps } from './UnselectedCardProduct.props'
import Root from './UnselectedCardProduct.styles'

const UnselectedCardProduct = ({
  icon,
  title,
  secondaryIcon,
  disabled = false,
  description,
  code,
  label,
  onSelectCoverage,
}: UnselectedCardProductProps) => {
  const onIconClick = () => {
    setOpenModalCoverage(true)
    analytics.dashboard.upsell.calculator.clickHelpCoverage.execute({
      type: title,
    })
  }

  const [openModalCoverage, setOpenModalCoverage] = React.useState(false)

  return (
    <Root>
      <Card shadow="100" className="card-product">
        <CardHeader>
          <CardTitle
            icon={icon as string}
            title={title}
            className="card-product__title"
          >
            <CardActions>
              <i
                className={`${secondaryIcon ?? 'icon-help'} card-product__icon`}
                onClick={onIconClick}
              />
            </CardActions>
          </CardTitle>
        </CardHeader>
        <hr />
        <div className="card-product__description">
          <Typography
            className="card-product__description--text"
            variant="body3"
          >
            {description}
          </Typography>
          <Toggle
            className="card-product__description--toggle"
            id={code}
            disabled={disabled}
            onChange={() => onSelectCoverage()}
            label={label ?? 'Quero'}
          />
        </div>
      </Card>
      <ModalCoverageDetails
        open={openModalCoverage}
        onClose={() => setOpenModalCoverage(false)}
        code={code}
        onSelect={onSelectCoverage}
        canAddCoverage={true}
      />
    </Root>
  )
}

export default UnselectedCardProduct
