import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    margin: 0 0 ${theme.spacing[3]}px 0;

    @media (min-width: 1302px) {
      margin: 0 ${theme.spacing[6]}px ${theme.spacing[3]}px 0;
    }

    hr {
      border: 0;
      border: 1px solid ${theme.palette.neutral[100]};
      margin: ${theme.spacing[3]}px 0;
    }

    .card-product {
      &__title {
        .card-header_content {
          align-items: center;
        }

        span:not([class^='icon-']) {
          ${theme.typography.bodyBold2};
          color: ${theme.palette.neutral[500]} !important;
          margin-right: 12px;
        }
      }

      &__icon {
        font-size: 1.25rem;
        color: ${theme.palette.neutral[400]};
        cursor: pointer;
      }

      &__description {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--text {
          color: ${theme.palette.neutral[400]};
          margin-right: ${theme.spacing[4]}px;
        }
      }
    }
  `,
)

export default Root
