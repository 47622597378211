import React from 'react'

import analytics from '@azos/analytics'
import { ChatAvatarCard } from '@azos/shared'
import {
  InputHeight,
  InputWeight,
  RadioButton,
} from '@azos/shared/src/components/v2'
import { Button } from '@azos/shared/src/components/v2'
import { BackButton } from '@presentation/components/molecules/Upsell'
import { useSimulation } from '@presentation/hooks/useSimulation.hooks'
import { useUpsell } from '@presentation/providers/UpsellProvider'
import { Formik, Form } from 'formik'

import { ImcSmokerProps, ImcSmokerValue } from './ImcSmoker.props'
import Root from './ImcSmoker.styles'
import { validationSchema } from './ImcSmoker.validation'

const ImcSmoker: React.VFC<ImcSmokerProps> = ({ onGoBack }) => {
  const { useStep, formData, setFormData } = useSimulation()
  const { specialistUpsell } = useUpsell()

  const initialValues: ImcSmokerValue = {
    height: formData.height ? String(Number(formData.height).toFixed(2)) : '',
    weight: formData.weight ? String(formData.weight) : '',
    is_smoker: formData.is_smoker !== undefined ? formData.is_smoker : null,
  }

  const [step, { goToNextStep }] = useStep

  if (step !== 1) {
    return null
  }

  React.useEffect(() => {
    analytics.dashboard.upsell.simulation.viewSimulationPageSmokerIMC.execute()
  }, [])

  const handleSubmit = values => {
    setFormData({
      ...formData,
      ...values,
    })

    analytics.dashboard.upsell.simulation.continueSimulationPageSmokerIMC.execute()

    goToNextStep()
  }

  const OPTIONS = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]

  const handleGoBack = () => {
    analytics.dashboard.upsell.simulation.goBackSimulationPageSmokerIMC.execute()
    onGoBack()
  }

  return (
    <div>
      <BackButton onClick={handleGoBack} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          setFieldValue,
          handleChange,
          handleBlur,
          isSubmitting,
          errors,
          isValid,
          values,
          dirty,
          touched,
        }) => {
          const hasDefaultValues =
            !!initialValues?.height ||
            !!initialValues?.weight ||
            !!initialValues?.is_smoker
          const isDirty = hasDefaultValues || dirty
          return (
            <Form>
              <Root>
                <ChatAvatarCard
                  title="Muito bom!"
                  subtitle="Vamos precisar atualizar algumas informações."
                  description="Preencha as informações iniciais a seguir."
                  specialist={specialistUpsell}
                />
                <div className="imc-smoker__card">
                  <div className="imc-smoker__card--input">
                    <InputHeight
                      data-testid="upsell-height"
                      name="height"
                      placeholder="metros"
                      value={values.height}
                      className="imc-smoker__card--input-height"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={!!errors.height && touched.height}
                      helperText={touched.height ? errors.height : ''}
                    />
                    <InputWeight
                      data-testid="upsell-weight"
                      name="weight"
                      value={values.weight}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={!!errors.weight && touched.weight}
                      helperText={touched.weight ? errors.weight : ''}
                    />
                  </div>

                  <p>
                    Fumou nicotina ou cigarros eletrônicos nos últimos 2 anos?
                  </p>
                  <div className="imc-smoker__card--button">
                    <RadioButton
                      name="gender"
                      columns={2}
                      options={OPTIONS}
                      value={values.is_smoker}
                      onChange={value => setFieldValue('is_smoker', value)}
                      error={!!errors.is_smoker && touched.is_smoker}
                      helperText={errors.is_smoker}
                      disabled={isSubmitting}
                      className="imc-smoker__card--radio-button"
                    />
                  </div>
                  <Button
                    variant="primary"
                    fullWidth
                    className="imc-smoker__card--button-continue"
                    type="submit"
                    disabled={!isValid || !isDirty}
                  >
                    Continuar
                  </Button>
                </div>
              </Root>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ImcSmoker
