import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .payment-period {
      margin-bottom: 16px;

      .payment-period__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
      }

      .payment-period__subtitle {
        font-size: 14px;
        font-weight: 500;
        color: #505969;
      }
    }

    .radio-button__wrapper {
      margin-bottom: 12px;

      button {
        max-height: 56px;
        padding: 18px;

        &:not(.checked) {
          background-color: ${theme.palette.neutral[50]};
        }
      }
    }
  `,
)
