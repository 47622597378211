import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'

import { Root } from './BillingDateCard.styles'

interface BillingDateCardProps {
  originalDate: string
  displayDates: number
  active: boolean
  onClick: (billingDate: string) => void
}

const BillingDateCard: React.FC<BillingDateCardProps> = ({
  originalDate,
  displayDates,
  onClick,
  active,
}) => {
  return (
    <Root $active={active}>
      <div className="billing-date-card" onClick={() => onClick(originalDate)}>
        <Typography>
          <strong className="billing-date-card__text">{displayDates}</strong>
        </Typography>
      </div>
    </Root>
  )
}

export default BillingDateCard
