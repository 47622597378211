import React from 'react'

import { Box, Button, Typography, useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import {
  ReferralContextType,
  ShareContentType,
} from '@azos/analytics/src/domain/models'
import { ChatAvatarCard, NoSSR, ReferralCard } from '@azos/shared'
import { Input } from '@azos/shared/src/components/v2'
import utms from '@main/config/utms'
import { BackButton } from '@presentation/components/molecules/Upsell'
import { useUpsell } from '@presentation/providers/UpsellProvider'
import { Formik, Form } from 'formik'
import { useRouter } from 'next/router'

import { initialValues, UpsellShareFormValues } from './UpsellShare.props'
import { Root } from './UpsellShare.styles'
import { validationSchema } from './UpsellShare.validations'

const UpsellShare = () => {
  const { user } = useAuth()
  const { addNotification } = useNotification()
  const { specialistUpsell } = useUpsell()
  const router = useRouter()

  const referralUTM = React.useMemo(
    () => ({
      utm_source: utms.general.utm_source,
      utm_medium: utms.general.utm_medium,
      utm_campaign: user?.uid,
    }),
    [user],
  )

  const handleSubmit = (values: UpsellShareFormValues) => {
    analytics.dashboard.upsell.contract.sendReferralForm.execute({
      type: {
        nome: values.name,
        email: values.email,
      },
    })

    addNotification({
      description: 'Solicitação enviada com sucesso!',
      variant: 'success',
    })
  }

  React.useEffect(() => {
    analytics.dashboard.upsell.contract.viewUpsellSharePage.execute()
  }, [])

  const handleGoBack = () => {
    analytics.dashboard.upsell.contract.gobackSharePage.execute()

    router.back()
  }

  return (
    <NoSSR>
      <Root>
        <div className="upsell-share__button">
          <BackButton onClick={handleGoBack} />
        </div>
        <div className="upsell-share__chat-wrapper">
          <ChatAvatarCard
            title="Entendi. Nós vendemos apenas para titular da conta."
            description="Nesse caso, a pessoa que deseja contratar o seguro deve criar uma conta Azos e solicitar a contratação. "
            className="upsell-share__chat"
            specialist={specialistUpsell}
          />
        </div>

        <Box className="upsell-share__content">
          <div className="upsell-share__content-header">
            <i className="icon-beneficiary-color upsell-share__icon">
              <i className="path1"></i>
              <i className="path2"></i>
              <i className="path3"></i>
            </i>
            <Typography variant="bodyBold">Faça sua indicação</Typography>
          </div>
          <Typography
            variant="body2"
            className="upsell-share__content-description"
          >
            Informe a seguir o contato da pessoa que você deseja indicar a Azos.
            A nossa equipe de atendimento entrará em contato.
          </Typography>

          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
            }) => (
              <Form className="upsell-share__form">
                <Input
                  fullWidth
                  id="name"
                  name="name"
                  label="Nome da indicação"
                  placeholder="Nome do parente ou amigo..."
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  label="E-mail da indicação"
                  placeholder="E-mail do parente ou amigo..."
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Button
                  fullWidth
                  type="submit"
                  className="upsell-share__form-button"
                  disabled={!isValid}
                >
                  Enviar solicitação
                </Button>
              </Form>
            )}
          </Formik>
        </Box>

        <ReferralCard
          utm={referralUTM}
          context={ReferralContextType.UPSELL}
          className="upsell-share__referral"
          isInsured={user?.isInsured}
          customCopyEvent={() =>
            analytics.dashboard.upsell.contract.copyShareContent.execute({
              type: ShareContentType.ICON,
            })
          }
          customShareEvent={() =>
            analytics.dashboard.upsell.contract.copyShareContent.execute({
              type: ShareContentType.BUTTON,
            })
          }
          showTooltip
        />
      </Root>
    </NoSSR>
  )
}

export default UpsellShare
