import { CoveragesCode } from '@azos/core'
import {
  Collection,
  SelectedCoverage,
} from '@presentation/providers/CalculatorProvider/CalculatorProvider'

export type ProductItem = {
  code: CoveragesCode
  slug: string
  title: string
  subtitle: string
}

export const getCoverageSource = (collection: Collection) =>
  collection.collection

export const getSelectedCoverage = (
  collection: Collection,
  selectedCoverage: SelectedCoverage[],
  code: CoveragesCode,
) => selectedCoverage.find(i => i.code === code)?.data || collection.suggestion

export const getCoverageIcon = (coverageCode: CoveragesCode) => {
  switch (coverageCode) {
    case CoveragesCode.COD_0001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_1001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_2001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_2010_SEGURO_DE_VIDA_2:
    case CoveragesCode.COD_2012_MAC:
      return 'icon-heart'
    case CoveragesCode.COD_0002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_1002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_2002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_1009_SAFF:
    case CoveragesCode.COD_2009_SAFF:
      return 'icon-dove'
    case CoveragesCode.COD_0003_INVALIDEZ:
    case CoveragesCode.COD_1003_INVALIDEZ:
    case CoveragesCode.COD_2003_INVALIDEZ:
    case CoveragesCode.COD_2013_INVALIDEZ_2:
      return 'icon-wheelchair'
    case CoveragesCode.COD_0004_DOENCAS_GRAVES:
    case CoveragesCode.COD_0005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_1005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2011_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2015_DG30:
      return 'icon-virus'
    case CoveragesCode.COD_0006_DIH:
    case CoveragesCode.COD_1006_DIH:
    case CoveragesCode.COD_2006_DIH:
      return 'icon-hospital'
    case CoveragesCode.COD_2007_RIT:
      return 'icon-crutches'
    case CoveragesCode.COD_2008_CIRURGIAS:
      return 'icon-stethoscope'
    case CoveragesCode.COD_2014_IPTA_ESTENDIDA:
      return ''
  }
}
