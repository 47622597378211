import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    margin: 0 0 ${theme.spacing[3]}px 0;

    @media (min-width: 1302px) {
      margin: 0 ${theme.spacing[6]}px ${theme.spacing[3]}px 0;
    }

    hr {
      border: 0;
      border: 1px solid ${theme.palette.neutral[100]};
      margin: ${theme.spacing[3]}px 0;
    }

    .card-product {
      &__title {
        .card-header_content {
          align-items: center;
        }

        span:not([class^='icon-']) {
          ${theme.typography.bodyBold2};
          color: ${theme.palette.neutral[500]} !important;
          margin-right: 12px;
        }
      }

      &__icon {
        font-size: 1.25rem;
        color: ${theme.palette.neutral[400]};
      }

      &__input {
        height: 48px;
        background: ${theme.palette.neutral[100]};
        border: 1px solid ${theme.palette.neutral[200]};
        padding: 18px 12px;
      }

      &__slider {
        &-text {
          display: inline-block;
          margin-bottom: ${theme.spacing[2]}px;
        }
        &-input {
          margin-bottom: ${theme.spacing[4]}px;
        }
      }

      &__description {
        display: flex;
        flex-direction: column;

        @media (min-width: ${theme.breakpoints.md}px) {
          flex-direction: row;

          & > * {
            flex: 1;
          }

          gap: 32px;
        }

        &--text {
          color: ${theme.palette.neutral[400]};
          margin-right: ${theme.spacing[4]}px;
        }

        &--modal {
          &-button {
            display: flex;
            align-items: center;

            margin: ${theme.spacing[2]}px 0 ${theme.spacing[4]}px;
            cursor: pointer;
          }

          &-text {
            color: ${theme.palette.primary.main};

            i {
              margin-left: 12px;
            }
          }
        }
      }

      .label-wrapper > p {
        color: ${theme.palette.neutral[400]};
      }
    }
  `,
)

export default Root
