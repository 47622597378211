import React from 'react'

import { makeStorageProvider } from '@azos/core'
import { useProfessions } from '@presentation/hooks/useProfessions'
import { useSimulation } from '@presentation/hooks/useSimulation.hooks'
import { useUpsell } from '@presentation/providers/UpsellProvider'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'

import { SalaryProfession, ImcSmoker } from './components'
import { Root } from './SimulationUpsell.styles'

const UPSELL_DURATION_TIME = '@azos/upsellDuration'
const storageProvider = makeStorageProvider()

const SimulationUpsell: React.VFC = () => {
  const { useStep } = useSimulation()
  const { data: professions } = useProfessions()
  const { setSection } = useUpsell()
  const router = useRouter()

  const [step, helpers] = useStep

  const { goToPrevStep } = helpers

  React.useEffect(() => {
    setSection(1)
    storageProvider.set(UPSELL_DURATION_TIME, dayjs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = () => {
    if (step === 1) {
      router.push('/simular-novo-seguro')
    }
    goToPrevStep()
  }

  return (
    <Root>
      <ImcSmoker onGoBack={handleBack} />
      <SalaryProfession professions={professions} onGoBack={handleBack} />
    </Root>
  )
}

export default SimulationUpsell
