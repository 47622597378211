import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    .salary-profession__card {
      max-width: 440px;
      width: 100%;
      background-color: ${theme.palette.neutral[50]};
      margin-top: 24px;
      padding: 24px;

      p {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
      }

      &--input {
        display: flex;

        &-height {
          padding-right: 12px;
        }

        input {
          margin-bottom: 12px;

          ::-webkit-input-placeholder {
            text-align: end;
            font-size: 12px;
          }
        }
      }

      &--salary {
        button {
          cursor: pointer;
          background: #f3f4f6;
          border-radius: 0;
          padding: 12px;
          margin: 0;
          color: #090a0b;

          input {
            display: none;
          }

          label[for='salary']::after {
            display: none;
          }

          :hover {
            border-color: none;
          }

          &:active,
          &.checked {
            color: #005700;
            background-color: #e0f5e0;
          }

          &:focus,
          &.checked {
            outline: none;
            border: 2px solid #005700;
            color: #005700;
            background-color: #e0f5e0;
          }
        }

        label {
          min-width: 0;
        }

        label:before {
          border: none;
          content: unset;
        }
      }

      &--autocomplete {
        margin-bottom: 24px;
      }

      &--description {
        margin-top: ${theme.spacing[4]}px;
      }

      &--button {
        display: flex;

        &-continue {
          margin-top: 40px;
        }
      }
    }
  `,
)

export default Root
