import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css, styled } from '@azos/shared'

export const ErrorContainer = styled(Box)<BoxProps>(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  `,
)

export const Root = styled('div')(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.md}px) {
      padding: 0;
    }

    .checkout {
      &__header {
        width: 100%;
        max-width: 1080px;

        padding: ${theme.spacing[4]}px ${theme.spacing[3]}px 12px;

        @media (min-width: ${theme.breakpoints.md}px) {
          padding: 0 80px 12px;
        }
      }
      &__wrapper {
        display: grid;
        margin-bottom: 120px;

        @media (min-width: ${theme.breakpoints.md}px) {
          padding: 0 80px 80px;
          grid-template-columns: 1fr minmax(min-content, 295px);
          gap: 40px;
          margin-bottom: 0;
        }

        &-content {
          padding: 0 ${theme.spacing[3]}px ${theme.spacing[4]}px;
          min-height: calc(100vh - 300px);

          @media (min-width: ${theme.breakpoints.md}px) {
            padding: 0;
          }
        }
      }

      &__coverage-resume {
        margin-top: 0;
      }

      &__back-button {
        margin: ${theme.spacing[4]}px 0;
      }

      &__button-credit-card {
        margin-top: ${theme.spacing[6]}px;
      }

      &__divider {
        border: 1px solid ${theme.palette.neutral[100]};
        margin: ${theme.spacing[3]}px 0;
      }

      &__card {
        &--header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > div {
            display: flex;

            & > * + * {
              margin-left: ${theme.spacing[3]}px;
            }
          }

          i {
            font-size: 1.5rem;

            &.active {
              color: ${theme.palette.primary.main};
            }
          }
        }
      }

      &__form {
        & > * + * {
          margin-top: ${theme.spacing[3]}px;
        }
      }

      &__title {
        display: inline-block;
        margin-bottom: 12px;
      }
    }
  `,
)
