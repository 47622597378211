import React from 'react'

import { Button, Text } from '@azos/shared/src/components/v2'
import Head from 'next/head'

import { ErrorIcon, Root } from './NotFound.styles'

const NotFound = () => {
  return (
    <>
      <Head>
        <title>Página não encontrada | Azos - Um seguro para a sua vida</title>
      </Head>
      <Root>
        <div className="content">
          <ErrorIcon />
          <Text variant="h4">
            Desculpe, não conseguimos encontrar essa página
          </Text>
          <Text variant="body2">
            Tente digitar o endereço novamente ou volte para nossa página
            inicial.
          </Text>
          <Button
            color="primary"
            type="submit"
            variant="primary"
            fullWidth
            onClick={() => (window.location.href = 'https://www.azos.com.br/')}
          >
            Ir para página inicial
          </Button>
        </div>
      </Root>
    </>
  )
}

export default NotFound
