import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    margin: 0 0 ${theme.spacing[3]}px 0;

    @media (min-width: 1302px) {
      margin: 0 ${theme.spacing[6]}px ${theme.spacing[3]}px 0;
    }

    hr {
      border: 0;
      border: 1px solid ${theme.palette.neutral[100]};
      margin: 0;
    }

    .refused-card-product {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing[3]}px;

      &__title {
        .card-header_content {
          align-items: center;

          [class^='icon-'] {
            color: ${theme.palette.neutral[300]};
          }
        }
      }

      &__icon {
        font-size: 1.25rem;
        color: ${theme.palette.neutral[300]};
        cursor: pointer;
      }

      &__description {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--text {
          margin-right: ${theme.spacing[4]}px;
        }
      }

      &__refused {
        &-content {
          display: flex;
          align-items: flex-start;
          background: ${theme.palette.neutral[100]};
          margin-top: ${theme.spacing[3]}px;
          padding: 12px;

          i {
            font-size: 1.25rem;
            color: ${theme.palette.neutral[300]};
            margin-right: ${theme.spacing[3]}px;
          }
        }
      }

      &__alert {
        display: flex;
        align-items: center;
        gap: ${theme.spacing[2]}px;
        background-color: ${theme.palette.neutral[100]};
        padding: ${theme.spacing[2]}px;

        i {
          font-size: 20px;
          color: ${theme.palette.neutral[300]};
        }
      }
    }
  `,
)

export default Root
