import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { getCoverageIcon } from '@main/config/coverages'

import { RefusedCardProduct } from '../RefusedCardProduct'
import { RefusedCoveragesProps } from './RefusedCoverages.props'
import Root from './RefusedCoverages.styles'

const RefusedCoverages: React.VFC<RefusedCoveragesProps> = ({
  coverages,
  className,
}) => {
  const renderCoverages = coverages?.map(coverage => {
    const icon = getCoverageIcon(coverage.code)

    return (
      <div className="refused-coverages__coverages" key={coverage.info.slug}>
        <RefusedCardProduct
          icon={icon}
          title={coverage.info.name}
          description={coverage.info.description}
          code={coverage.code}
          reason={coverage.reason}
        />
      </div>
    )
  })

  return (
    <Root className={className}>
      <div className="refused-coverages">
        <div className="refused-coverages-content">
          <Typography
            variant="bodyBold"
            className="refused-coverages-content__title"
          >
            Incompatível
          </Typography>

          <Typography
            variant="body2"
            className="refused-coverages-content__subtitle"
          >
            No momento, não foi possível aprovar com as nossas seguradoras
            parceiras as coberturas a seguir:
          </Typography>
        </div>
        {renderCoverages}
      </div>
    </Root>
  )
}

export default RefusedCoverages
